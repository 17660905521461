import React from "react";
import Footer from "../components/Footer";
import Menu from "../components/Menu";
import Reserve from "../components/Reserve";

function Reservation(props) {
  return (
<>
      <Menu />
    
        <Reserve />
  

  
      </>
  );
}

export default Reservation;
